<script setup lang="ts">
import {AlgoliaProduct} from "~/types/AlgoliaResult";
import useDynamicThumbnail from "~/composables/useDynamicThumbnail";
import Cookies from "js-cookie";

const props = withDefaults(
    defineProps<{
        product: AlgoliaProduct;
        hasImage?: boolean;
        minimal?: boolean;
    }>(),
    {
        hasImage: true,
        minimal: true,
    }
);

const {product, minimal} = toRefs(props);
provide('product', product);

const showLowStockMessage = ref(false);
provide('showLowStockMessage', showLowStockMessage);
const storeMode = Cookies.get("mode") ?? "privat";

const getProductImage = computed(() => {
    return useDynamicThumbnail(props.product.images[0]?.url, 64, 64);
});

function truncateString(str: string | null, num: number) {
    if (!str) {
        return "";
    }
    if (str.length <= num) {
        return str;
    }

    return str.slice(0, num) + "...";
}

const hasTierPrice = ref(false);
const price = computed(() => {
    if (!product.value.prices && !product.value.price) return null;
    if (product.value.prices?.length === 0) return product.value.price;
    hasTierPrice.value = true;

    let cheapest = null;
    const firstKey = Object.keys(product.value.prices)[0];
    for (let key in product.value.prices[firstKey]) {
        const p = product.value.prices[firstKey][key];
        if (cheapest === null || p.gross < cheapest.gross) {
            cheapest = p;
        }
    }
    return cheapest;
});

const getProductName = (product) => {
    let name = product.name;

    if (product.customFields?.product_subtitle) {
        return name + ' ' + props.product.customFields?.product_subtitle;
    }
    return name;
}

const getStoreModePrice = (price) => {
    if (storeMode == 'erhverv') {
        return price.net;
    }
    return price.gross;
}
</script>
<template>
<div
    class="flex relative overflow-hidden items-center gap-3 mx-8 my-3 cursor-pointer h-fit"
>
    <div class="border-1 w-12 border-gray-200 overflow-hidden flex flex-none"
         v-if="props.hasImage && getProductImage">
        <NuxtImg
            height="64"
            :src="getProductImage"
            class="h-16 mx-auto"
            :alt="product.name"
            loading="lazy"
        />
    </div>
    <div class="flex flex-col sm:flex-row justify-between w-full">
        <div class="flex items-center justify-between overflow-hidden gap-5 grow">
            <div
                data-test-id="layout-search-suggest-name"
                class="overflow-hidden text-ellipsis normal-case text-gray-500 text-sm"
                :class="{
                  'pl-4': !props.hasImage
                }"
            >
                <div>
                    {{ truncateString(getProductName(product), 80) }}
                </div>
                <div v-if="!minimal" class="flex items-baseline">
                    <SharedPrice
                        :value="getStoreModePrice(price)"
                        class="mr-4 font-semibold"
                    >
                        <template v-slot:beforePrice v-if="hasTierPrice">
                            <span>{{ $t('general.from') }}</span>
                        </template>
                    </SharedPrice>
                    <div>
                        <p v-if="showLowStockMessage" class="text-brand-red text-sm my-3">{{ $t('listing.stockExceeded', {quantity: product.availableStock}) }}</p>
                        <p v-else-if="product.isCloseout && product.availableStock <= 0" class="text-sm my-3 text-brand-red">{{ $t('listing.soldOut') }}</p>
                        <p v-else-if="product.availableStock > 0" class="text-brand-green text-sm my-3">{{ $t('listing.inStock') }}</p>
                        <p v-else class="text-sm my-3 text-brand-red">{{ $t('listing.outOfStock') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ProductCardAddToCart :parent="'ProductSuggestSearch'"/>
</div>
</template>
